//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from 'quasar'
// const brand = 'ZBXN';
export default {
    name: "Index",
    data() {
        return {
            tab: 'basic',
            basicInfo: {},
            custInfo: {},
            dic1: [
                {id: 'SAP_0000000375', txt: '从事行业', value: ''},
                {id: 'SAP_0000000110', txt: '职业', value: ''},
                {id: 'SAP_0000000111', txt: '其它职业', value: ''},
                {id: 'SAP_0000000116', txt: '年收入', value: ''},
                {id: 'SAP_0000000133', txt: '肤色', value: ''},
                {id: 'SAP_0000000134', txt: '身高', value: ''},
                {id: 'SAP_0000000108', txt: '结婚纪念日', value: ''},
                {id: 'ZMARRY', txt: '婚姻状况', value: ''},
                {id: 'ZCONTACT', txt: '接受联系', value: ''},
            ],
            dic2: [
                {id: 'MFASHION', txt: 'VIP穿衣风格', value: ''},
                {id: 'MCON', txt: 'VIP消费能力', value: ''},
                {id: 'SAP_0000000112', txt: '上衣号码', value: ''},
                {id: 'SAP_0000000113', txt: '裤装号码', value: ''},
                {id: 'SAP_0000000114', txt: '鞋号', value: ''},
                {id: 'SAP_0000000130', txt: '其他爱好', value: ''},
                {id: 'SAP_0000000131', txt: '家庭成员数', value: ''},
            ],
            dic3: [
                {id: 'SAP_0000000063', txt: '首购原因', type: 'checkbox'},
                {id: 'SAP_0000000066', txt: '首购服装类型', type: 'checkbox'},
                {id: 'SAP_0000000071', txt: '喜欢穿着的男装品牌', type: 'checkbox'},
                {id: 'SAP_0000000135', txt: '体型', type: 'radio'},
                {id: 'SAP_0000000136', txt: '着装风格', type: 'checkbox'},
                {id: 'SAP_0000000109', txt: '沟通方式偏好', type: 'checkbox'},
                {id: 'SAP_0000000118', txt: '爱好', type: 'checkbox'},
                {id: 'SAP_0000000377', txt: '获取品牌渠道', type: 'checkbox'},
                {id: 'SAP_0000000378', txt: '消费类型', type: 'radio'},
                {id:'SAP_0000000379',txt:'购买习惯',type:'radio'},
                {id: 'SAP_0000000365', txt: '喜欢的礼品', type: 'checkbox'},
                {id: 'SAP_0000000380', txt: '喜爱的电影', type: 'checkbox'},
                {id: 'SAP_0000000381', txt: '感兴趣的活动', type: 'checkbox'},
                {id:'SAP_0000000382',txt:'喜欢的杂志',type:'checkbox'},
                {id: 'SAP_0000000383', txt: '经常关注的信息', type: 'checkbox'},
                {id:'SAP_0000000384',txt:'喜欢的运动方式',type:'checkbox'},
                {id:'SAP_0000000385',txt:'喜爱的饮品',type:'checkbox'},
                {id:'SAP_0000000386',txt:'喜爱的饮食',type:'checkbox'},
                {id:'SAP_0000000387',txt:'喜爱的公益方式',type:'checkbox'},
                {id:'SAP_0000000388',txt:'喜爱的投资',type:'checkbox'}
            ],
            dic3Map: {},
            activeItem: '',
            currentMobile: null,
            currentBpid: null,

            haveSend: false,
            showPicker: false,
            minDate: new Date(1910, 0, 1),
            maxDate: new Date(),
            currentDate: new Date(),
            newPhone: '',
            newName: '',
            newBirth: '',

            currentUserId: '',
            loading: true,
            showNothing: false,
            bindType:false
        }
    },
    created() {
        window.document.title = '详细信息';
    },
    mounted() {
        window.document.title = '详细信息';
        const that = this;

        // 测试先注释
        this.$toast.loading({
            message: '',
            forbidClick: true,
            duration: 0
        });

        this.wxConfig(function () {
            that.wxAgentConfig();
        })

        //测试直接调用
        // let userId = 'wmHleoCQAAEYG93ggzb9hPLrnQ0DQ5sg';
        // this.currentUserId = userId;
        // that.getExternalInfoNew(userId);
    },
    activated() {
        window.document.title = '详细信息';
    },
    methods: {
        wxConfig(callback){
            let agentId = LocalStorage.getItem('agentId');
            let corpId = LocalStorage.getItem('corpId');
            // alert(agentId);
            // alert(corpId);
            // alert(window.location.href.split('#'));
            this.axios.get('/' + this.$ajaxPrefix.consumer + '/v1/wx/cp/getJsSdkConfig', {
                params: {
                    agentId,
                    corpId,
                    url: encodeURIComponent(window.location.href.split('#'))
                }
            }).then(res=>{
                if (res && res.data && res.data.data){
                    const temp = res.data.data;
                    wx.config({
                        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                        // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: temp.appId, // 必填，企业微信的corpID
                        timestamp: temp.timestamp, // 必填，生成签名的时间戳
                        nonceStr: temp.nonceStr, // 必填，生成签名的随机串
                        signature: temp.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                        jsApiList: ['chooseImage'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                    });
                    wx.ready(function () {
                        callback&&callback();
                    })
                }else {
                    this.loading = false;
                    this.$toast.clear();
                    this.showNothing = true;
                    // this.$toast({message: '获取应用权限失败!', duration: 5000});
                }
            }).catch(()=>{
                console.log('一茶馆');
                this.loading = false;
                this.$toast.clear();
                this.showNothing = true;
                // this.$toast({message: '获取应用权限失败!', duration: 5000});
            });
        },

        wxAgentConfig(){
            const that = this;
            let agentId = LocalStorage.getItem('agentId');
            let corpId = LocalStorage.getItem('corpId');
            this.axios.get('/' + this.$ajaxPrefix.consumer + '/v1/wx/cp/getAgentJsSdkConfig', {
                params: {
                    agentId,
                    corpId,
                    url: encodeURIComponent(window.location.href.split('#'))
                }
            }).then(res=>{
                if (res && res.data && res.data.data){
                    const temp = res.data.data;
                    wx.agentConfig({
                        debug: true,
                        corpid: corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
                        agentid: agentId, // 必填，企业微信的应用id （e.g. 1000247）
                        timestamp: temp.timestamp, // 必填，生成签名的时间戳
                        nonceStr: temp.nonceStr, // 必填，生成签名的随机串
                        signature: temp.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
                        jsApiList: ['getCurExternalContact'], //必填
                        success: function() {
                            that.getExternal();
                        },
                        fail: function() {
                            that.$toast.clear();
                            that.$toast({message: '获取应用权限失败', duration: 5000});
                        }
                    });
                }else {
                    that.$toast.clear();
                    that.$toast({message: '获取应用权限失败', duration: 5000});
                }
            });
        },

        getExternal(){
            const that = this;
            // 回调
            wx.invoke('getCurExternalContact', {
            }, function(res__){
                if(res__.err_msg == "getCurExternalContact:ok"){
                    let userId  = res__.userId; //返回当前外部联系人userId
                    if (userId){
                        that.currentUserId = userId;
                        // that.getExternalInfo(userId);
                        that.getExternalInfoNew(userId);
                    }else {
                        that.$toast.clear();
                    }
                }else if (res__.err_msg.indexOf('permission')){
                    //错误处理
                    that.$toast({message: '未配置客户联系功能 无法查询客户信息', duration: 5000});
                }else {
                    that.$toast({message: '获取外部联系人失败', duration: 5000});
                }
            });
        },

        // getExternalInfo(userId){
        //     this.axios.get('/' + this.$ajaxPrefix.consumer + '/v1/wx/cp/customer/getByEexternalUserId', {
        //         params: {
        //             userId
        //         }
        //     }).then(res=>{
        //        if (res && res.data && res.data.data){
        //            this.$toast.clear();
        //            this.basicInfo = res.data.data ;
        //            this.currentMobile = this.basicInfo.mobile ? this.basicInfo.mobile : null;
        //            this.currentBpid = this.basicInfo.bpid ? this.basicInfo.bpid : null;
        //            this.getCustInfo();
        //            this.getAllPortrait();
        //
        //        } else {
        //            this.$toast.fail('查无该联系人资料');
        //        }
        //     });
        // },

        getExternalInfoNew(userId){
            this.axios.get('/' + this.$ajaxPrefix.consumer + '/v1/wx/cp/customer/byEexternalUserId', {
            // this.axios.get('/v1/wx/cp/customer/byEexternalUserId', {
                params: {
                    userId
                }
            }).then(res=>{
                console.log(res);
                this.$toast.clear();
                this.loading = false;
                if (res && res.data && res.data.data){ //已经注册
                    this.haveSend = true;
                    this.$toast.clear();
                    this.basicInfo = res.data.data ;
                    this.currentMobile = this.basicInfo.mobile ? this.basicInfo.mobile : null;
                    this.currentBpid = this.basicInfo.bpid ? this.basicInfo.bpid : null;
                    this.getCustInfo();
                    this.getAllPortrait();

                }else if(res && res.data && res.data.code === 250){
                    this.showNothing = true;
                } else { //未注册
                    // this.$toast.fail('查无该联系人资料');
                    this.haveSend = false;

                }
            });
        },

        getCustInfo() {
            const that = this;
            this.custInfo = {};
            if (!that.currentMobile) return;
            this.axios.get('/' + this.$ajaxPrefix.consumer + '/v1/wx/cp/crm/consume/consumeGetCustConsumeInfoByMobileAndGuide', {
            // this.axios.get('/v1/wx/cp/crm/consume/consumeGetCustConsumeInfoByMobileAndGuide', {
                params: {
                    mobile: this.currentMobile,
                }
            }).then(res => {
                if (res && res.data && res.data.data && res.data.data.consumeItems) {
                    const temp = res.data.data.consumeItems;
                    let keys = new Set();
                    const custObj = {};
                    temp.forEach(item => {
                        keys.add(item.rlbNum);
                    });
                    const keyArr = Array.from(keys);
                    keyArr.forEach(item => {
                        custObj[item + ''] = []
                    });
                    temp.forEach(item => {
                        custObj[item.rlbNum + ''].push(item);
                    });

                    that.custInfo = custObj;
                }else {
                    that.custInfo = {};
                }
            })
        },

        getDetail() {
            const that = this;
            const dic3Map = this.dic3Map;
            this.axios.get('/' + this.$ajaxPrefix.consumer + '/v1/wx/cp/crm/customer/getDetail', {
            // this.axios.get('/v1/wx/cp/crm/customer/getDetail', {
                params: {
                    bpid: that.currentBpid
                }
            }).then(res => {
                if (res && res.data && res.data.data) {
                    const temp = res.data.data;
                    temp.forEach(item => {
                        that.dic1.forEach(item1 => {
                            if (item1['id'] == item['zkey']) {
                                item1['value'] == item['zvalue']
                            }
                        });
                        that.dic2.forEach(item1 => {
                            if (item1['id'] == item['zkey']) {
                                item1['value'] == item['zvalue']
                            }
                        });
                        for (let key in dic3Map) {
                            if (item['zkey'] == key) {
                                dic3Map[key].forEach(_item => {
                                    if (_item.value == item['zvalue']) {
                                        _item.selected = 'checked';
                                    }
                                })
                            }
                        }
                    });
                    that.dic3Map = dic3Map;
                }
            })
        },

        getAllPortrait() {
            const that = this;
            const dic3Map = {};
            this.dic3.forEach(item => {
                dic3Map[item.id] = [];
            });
            this.axios.get('/' + this.$ajaxPrefix.consumer + '/v1/wx/cp/crm/customer/getAllPortrait').then(res => {
            // this.axios.get('/v1/wx/cp/crm/customer/getAllPortrait').then(res => {
                if (res && res.data && res.data.data) {
                    const temp = res.data.data;
                    temp.forEach(item => {
                        that.dic3.forEach(item1 => {
                            if (item.eigenName == item1.id) {
                                dic3Map[item1.id].push({
                                    value: item.eigenvalue,
                                    txt: item.eigenvalueText,
                                    selected: ''
                                })
                            }
                        });
                    });
                    that.dic3Map = dic3Map;
                    if (!that.currentBpid)return;
                    that.getDetail();
                }
            })
        },



        submitPhone(){
            if(!(/^1[23456789]\d{9}$/.test(this.newPhone))){
                this.$toast({message: '手机号有误 请重新输入', duration: 3000});
                return false;
            }
            this.$toast({message: '正在绑定 请稍候', duration: 5000});
            this.bindType=true
            this.axios.post('/' + this.$ajaxPrefix.consumer +'/v1/wx/cp/customer/reg?userId='+this.currentUserId, {
                birthday: this.newBirth.split('-').join(''),
                mobilePhone: this.newPhone,
                name: this.newName,
            }).then(res=>{
                console.log(res);
              //  
                if (res && res.data && res.data.msg == 'success' && res.data.data == '注册成功'){
                    this.haveSend = true;
                    this.$toast({message: '更新成功', duration: 2000});
                    this.getExternalInfoNew(this.currentUserId); //注册成功，重新获取用户信息
                }else {
                    this.$toast({message: res.data.msg, duration: 5000});
                    this.bindType=false
                }
            })
        },
        onConfirm(value){
            this.newBirth = this.$moment(value).format('yyyy-MM-DD');
            this.showPicker = false;
        }
    }
}
